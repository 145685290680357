
.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    zoom: 1;
    *display: inline;
    
}

.chosen-container * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    z-index: 990;
    width: 100%;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-top: 0;
    border-radius: 0 0 4px 4px
}

.chosen-container.chosen-with-drop .chosen-drop {
    left: 0
}

.chosen-container a {
    cursor: pointer
}

.chosen-container-single .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 18px;
    height: 61px;
    line-height: 66px;
    border: 1px solid #dbdbdb;
    border: 1px solid transparent;
    /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .08);*/
    background-color: #fff;
    color: #888;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 8px
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border-radius: 4px 4px 0 0
}

.chosen-container-active .chosen-drop,
.chosen-container-single .chosen-drop {
    opacity: 0;
    visibility: hidden;
    transition: all .3s
}

.chosen-container-active.chosen-with-drop .chosen-drop {
    opacity: 1;
    visibility: visible
}

.chosen-container-single .chosen-default {
    color: #999
}

.chosen-container-single .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #888
}

.chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px
}

.chosen-container-single .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%
}

.chosen-container-single .chosen-single div:after {
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-size: 18px;
    margin: 1px 0 0;
    right: 20px;
    position: relative;
    width: auto;
    height: auto;
    display: inline-block;
    color: silver;
    float: right;
    font-weight: 900;
    transition: transform .3s;
    transform: rotate(0deg)
}

.chosen-container-active.chosen-with-drop .chosen-single div:after {
    transform: rotateX(180deg)
}

.chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 8px;
    white-space: nowrap
}

.chosen-container-single .chosen-search input[type=text] {
    margin: 1px 0;
    padding: 6px 20px 6px 6px;
    width: 100%;
    height: 40px;
    outline: 0;
    margin-bottom: 10px!important;
    border: 1px solid #ddd!important;
    background-color: #fbfbfb!important;
    display: block;
    position: relative;
    z-index: 9;
    background-color: #fafafa;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .04)
}

.chosen-container-single .chosen-search:before {
    content: "\f002";
    font-family: fontawesome;
    font-size: 15px;
    margin: 0 15px 0 0;
    top: 12px;
    position: relative;
    width: 11px;
    height: 5px;
    display: inline-block;
    color: #aaa;
    float: right;
    font-weight: 400;
    z-index: 10;
    transition: transform .3s
}

.chosen-container-single .chosen-drop {
    margin-top: -1px;
    background-clip: padding-box
}

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px
}

.chosen-container .chosen-results {
    color: #888;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 8px 0;
    padding: 0 4px 0 8px;
    max-height: 300px
}

.chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 9px 10px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none
}

.chosen-container-multi .chosen-results li {
    padding: 10px 18px;
    transition: color .3s
}

.chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer
}

.chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: #ccc;
    cursor: default
}

.chosen-container .chosen-results li.highlighted {
    background-color: transparent;
    border-radius: 0;
    color: #66676b;
    transition: color .2s ease-in-out
}

.chosen-container .chosen-results li.no-results {
    color: #888;
    display: list-item;
    background-color: #fff
}

.chosen-container-multi .chosen-results li.no-results {
    background-color: #fff
}

.chosen-container .chosen-results li.group-result {
    display: list-item;
    font-weight: 700;
    cursor: default
}

.chosen-container .chosen-results li.group-option {
    padding-left: 15px
}

.chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline
}

.chosen-container-multi .chosen-choices {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 18px;
    height: auto;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    text-decoration: none;
    white-space: nowrap;
    line-height: 33px;
    cursor: pointer;
    margin: 0;
    border-radius: 8px;
    
}

.chosen-container-multi.chosen-with-drop .chosen-choices {
    border-radius: 4px 4px 0 0
}

.chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none
}

.chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap
}

.chosen-container-multi .chosen-choices li.search-field input[type=text] {
    margin: 0;
    padding: 0;
    height: 49px;
    outline: 0;
    border: 0!important;
    background: 0 0!important;
    color: #888;
    line-height: normal;
    font-weight: 500;
    box-shadow: none;
    transition: none
}

.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 10px 6px 0 -4px;
    padding: 0 25px 0 8px;
    max-width: 100%;
    background-color: #66676b;
    line-height: 29px;
    color: #fff;
    cursor: default;
    font-weight: 500;
    border-radius: 3px
}

.chosen-container-multi .chosen-choices li.search-choice span {
    word-wrap: break-word
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: -2px;
    right: 7px;
    display: block
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
    content: "\f00d";
    font-family: fontawesome;
    font-size: 13px;
    top: 1px;
    position: relative;
    width: 11px;
    height: 5px;
    display: inline-block;
    color: #fff;
    float: right;
    font-weight: 400
}

.chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #ccc;
    background-color: #e4e4e4;
    color: #888
}

.chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #d4d4d4
}

.chosen-container-multi .chosen-results {
    margin: 0;
    padding: 0
}

.chosen-container-multi .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default
}

.chosen-container.chosen-container-multi .chosen-drop {
    opacity: 0;
    visibility: hidden;
    padding: 10px 0
}

.chosen-container.chosen-container-multi.chosen-with-drop.chosen-container-active .chosen-drop {
    opacity: 1;
    visibility: visible
}

.chosen-container-active .chosen-single,
.chosen-container-active .chosen-choices,
.chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #e0e0e0
}

.chosen-container-active.chosen-with-drop .chosen-single div {
    border-left: none;
    background: 0 0
}

.chosen-container-active .chosen-choices li.search-field input[type=text] {
    color: #888!important
}

.chosen-drop::-webkit-scrollbar {
    width: 10px
}

.chosen-drop::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .05);
    border-radius: 10px
}

.chosen-drop::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .2)
}

.chosen-drop::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .3)
}